import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function Services() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }


    return (
        <div id="body" data-theme={colorMode}>
            <div className="page">
                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '24px', }}>
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="content">
                        <label id="patientReferralTitle">Services Offered at Carolina OMR</label>
                        <div id="page_selection">
                            <div style={{width: '700px'}}>
                                <div className="servicesContainer">
                                    <label id="serviceLabel" >The following services offered by Carolina OMR reflect over 20 years of experience with CBCT. 
                                        Owning and operating 8 CBCT machines, Dr. Howerton has overseen thousands of data acquisitions as well as completed thousands of reports. 
                                        Our services include:</label>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-evenly", marginTop: '50px', marginBottom: '50px', alignItems: "center", height: 'auto', width: 'auto'}}>
                                    <button style={{ 
                                        backgroundColor: '#232D3F', 
                                        color: 'white', 
                                        padding: '10px 20px', 
                                        border: 'none', 
                                        borderRadius: '5px' ,
                                        height: '50px',
                                        width: '200px'
                                        }}
                                        onClick={() => navigate("/cbct-radiology-reports")}>
                                        CBCT Radiology Report
                                    </button>
                                    <button style={{ 
                                        backgroundColor: '#232D3F', 
                                        color: 'white', 
                                        padding: '10px 20px', 
                                        border: 'none', 
                                        borderRadius: '5px',
                                        height: '50px',
                                        width: '200px'
                                        }}
                                        onClick={() => navigate("/implant-planning-software")}>
                                        Implant Planning Software
                                    </button>
                                    <button style={{ 
                                        backgroundColor: '#232D3F', 
                                        color: 'white', 
                                        padding: '10px 20px', 
                                        border: 'none', 
                                        borderRadius: '5px',
                                        height: '50px',
                                        width: '200px'
                                        }}
                                        onClick={() => navigate("/cbct-education")}>
                                        CBCT Education
                                    </button>
                                </div>
                                <div className="servicesContainer">
                                    <label id="serviceLabel">Dr. Howerton has been an expert consultant in numerous litigation cases regarding dental malpractice involving CBCT imaging. 
                                        Carolina OMR services empower dental practitioners to be confident and competent using CBCT technology to avoid pitfalls leading to litigation. 
                                        Carolina OMR strengths include CBCT knowledge and experience to convey peace of mind to the dental practitioner using CBCT.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                    <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                    <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                </div>
            </div>
        </div>
    )
}

export default Services;