import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function ImplantPlanningSoftware() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }


    return (
        <div id="body" data-theme={colorMode}>
            <div className="page">
                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '24px', }}>
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="">
                        <label id="patientReferralTitle">SIMPLANT Software (Precise and Consistant Placement)</label>
                        <div id="page_selection">
                            <div style={{display: 'flex', justifyContent: 'center', width: '900px'}}>
                                <div className="sharingServicesContainer">
                                    <img id="radiologyImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/Simplant%201.jpg/:/cr=t:0%25,l:3.79%25,w:92.41%25,h:100%25/rs=w:1015,h:1015,cg:true"></img>
                                </div>
                                <div className="sharingServicesContainer">
                                    <div>
                                        <label id="formServiceLabel">Precise placement of dental implants using implant planning software should include an OMR. 
                                            Many implant planning software applications suffer from three-dimensional volume renderings that do not mirror two-dimensional images and improperly positioned surgical guide metal cylinders as a result of misaligned STL files.</label>
                                        <label id="formServiceLabel">Carolina OMR is considered a Master SimPlant center. Dr. Howerton has years of experience controlling the aforementioned variables contributing to consistent, safe, and precise dental implant planning and placement. </label>
                                        <label id="formServiceLabel">A comprehensive review/report of DICOM data for findings/pathology is included.</label>
                                        <br></br>
                                        <label id="formServiceLabel">How it works-</label>
                                        <label id="formServiceLabel"> 
                                            1. Upload DICOM files into your Sharefile folder 
                                        </label>
                                        <label id="formServiceLabel">
                                            2. Fees range from $400-$550 depending on complexity.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div id="radiologyImageContainer">
                                <img id="implantImage2" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/Simplant%20%233.jpg/:/cr=t:0.58%25,l:0%25,w:100%25,h:98.84%25/rs=w:2480,h:1240,cg:true"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                    <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                    <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                </div>
            </div>
        </div>
    )
}

export default ImplantPlanningSoftware;