import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';
import SampleReport from "../assets/Report Example.pdf";


function CBCTRadiologyReport() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [account, setAccount] = React.useState(false);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    const handleAccount = () => {
        setAccount(!account);
    };

    const onChangeColorMode = () => {
        setColorMode(!colorMode);
    }


    return (
        <div id="body" data-theme={colorMode}>
            <div className="page">
                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '24px', }}>
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="mb-3">
                        <label id="patientReferralTitle">CBCT Radiology Reports</label>
                        <div id="page_selection">
                            <div style={{display: 'flex', justifyContent: 'center', width: '900px'}}>
                                <div className="sharingServicesContainer">
                                    <img id="radiologyImage" src="https://img1.wsimg.com/isteam/ip/3e153f97-75cf-441a-a94f-4351659383d5/web_report_pg_1.JPG/:/cr=t:0%25,l:0.98%25,w:98.04%25,h:100%25/rs=w:638,h:851,cg:true"></img>
                                </div>
                                <div className="sharingServicesContainer">
                                    <div>
                                        <label id="formServiceLabel">CBCT radiology reports should include specific information regarding a region of interest the referring practitioner requests as well as review of findings/pathology within the field of view. 
                                            Our written communication is concise and often includes well annotated images to ensure the reader clearly understands the details within the report.  
                                            The report will also serve as an excellent tool to share detailed information with collaborating dental/medical practitioners.</label>
                                        <br></br>
                                        <label id="formServiceLabel">Process to get DICOM data to Carolina OMR for review:</label>
                                        <br></br>
                                        <label id="formServiceLabel">1. <label id="formServiceLabelBold">DICOM file upload:</label> DICOM data is exported from your CBCT machine and shared using Sharefile (a HIPAA compliant web-based software).</label>
                                        <label id="formServiceLabel">2. <label id="formServiceLabelBold">Referral Form Completion:</label> the "Patient Referral" form (located on our website) allows easy "auto fill" text boxes as well as access to your personal Sharefile folder.</label>
                                        <label id="formServiceLabel">3. <label id="formServiceLabelBold">Report Delivery:</label> within three business days if not sooner.</label>
                                        <br></br>
                                        <label id="formServiceLabel">Dr. Howerton and Dr. Colvin are readily available to receive 
                                            phone calls regarding information within reports.  Report fees range from $95-$200 depending on time required to complete the report.</label>
                                        <button style={{ 
                                            display: 'flex', 
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#232D3F', 
                                            color: 'white', 
                                            padding: '10px 20px', 
                                            marginTop: '10px',
                                            border: 'none', 
                                            borderRadius: '5px',
                                            height: '50px',
                                            width: '250px'
                                            }}>
                                            <a href={SampleReport} without rel='noreferrer' target='_blank' style={{color: 'white'}}>
                                                To See Full Report Click Here
                                            </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                    <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                    <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                </div>
            </div>
        </div>
    )
}

export default CBCTRadiologyReport;