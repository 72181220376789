import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import logo from '../assets/CAROLINA.png';


function PrivacyPolicy() {
    const navigate = useNavigate();
    const [dimensions, setDimensions ] = React.useState(window);
    const [colorMode, setColorMode] = React.useState(false);

    useEffect(() => {

        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);
    
    return (
        <div id="body" data-theme={colorMode}>
            <div id="page">
                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '24px', }}>
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="content">
                        <label id="patientReferralTitle">Privacy Policy</label>
                        <div id="page_selection">
                            <div className="oneColumnContainer">
                                <div className="column">
                                    <label id="formSubLabel">In progress</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                    <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                    <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;