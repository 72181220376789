import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientMain.css";
import { FaCheck} from "react-icons/fa6";
import axios from "axios";
import logo from '../assets/CAROLINA.png';
import DatePicker from 'rsuite/DatePicker';
import 'rsuite/DatePicker/styles/index.css';
import { BsFileX, BsFileEarmarkCheck, BsXLg, BsFolder, BsFiles } from "react-icons/bs";
import JSZip from 'jszip';
import { Buffer } from "buffer";
import {useDropzone} from 'react-dropzone'


function PatientReferralForm() {
    const navigate = useNavigate();
    const [ip, setIP] = React.useState('');
    const [dimensions, setDimensions ] = React.useState(window);
    const [progress, setProgress] = React.useState(0);
    const [progressLabel, setProgressLabel] = React.useState('');
    const [file, setFile] = React.useState(null);
    const [fileType, setFileType] = React.useState(null);
    const [uploadID, setUploadID] = React.useState('');
    const [patient_scan_date, setPatientScanDate] = React.useState('');
    const [patient_dob_date, setPatientDOBDate] = React.useState('');
    const [regions_of_interest, setRegionsOfInterest] = React.useState('');

    const drop = React.useRef(null);
    const [dragging, setDragging] = React.useState(false);
    const [droppedFiles, setDroppedFiles] = React.useState([]);
    const [uploadType, setUploadType] = React.useState('');
    
    const handleFileBrowse = (e) => {
        e.preventDefault();
        console.log("file")
        setUploadType('file');
    }

    const handleFolderBrowse = (e) => {
        e.preventDefault();
        console.log("folder")
        setUploadType('folder');
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = [...e.dataTransfer.files];
        if (files && files.length) {
            setDroppedFiles(files);
            setFileType(files[0].type);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleFolderInput = (event) => {
        const files = [...event.target.files];
        if (files && files.length) {
            setDroppedFiles(files);
            setFileType(files[0].type);
        }
    };

    const fileRemove = (file) => {
        const files = [...droppedFiles];
        files.splice(droppedFiles.indexOf(file), 1);
        setDroppedFiles(files);
    }

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        axios.post("https://ipinfo.io" )
        .then((response) => {
            setIP(response.data.ip);
        })
        .catch((error) => {
            console.error("Error uploading the file:", error);
        });

        // drop?.current.addEventListener('dragover', handleDragOver);
        // drop?.current.addEventListener('drop', handleDrop);

        // drop.current.addEventListener('dragenter', handleDragEnter);
        // drop.current.addEventListener('dragleave', handleDragLeave);
        // document.body.style.overflow = "hidden";
        return () => {
            // document.body.style.overflow = "scroll";
            // drop.current.removeEventListener('dragover', handleDragOver);
            // drop.current.removeEventListener('drop', handleDrop);
            // drop.current.removeEventListener('dragenter', handleDragEnter);
            // drop.current.removeEventListener('dragleave', handleDragLeave);
        };
    }, [uploadType]);

    const [genderValue, setGenderValue] = React.useState('');
    const genderOptions = [
        { label: 'No Selection', value: 'No Selection' },
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
    ];
    const handleGenderChange = (event) => {
        setGenderValue(event.target.value);
    };

    const [ethnicityValue, setEthnicityValue] = React.useState('');
    const ethnicityOptions = [
        { label: 'No Selection', value: 'No Selection' },
        { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
        { label: 'White (Not Hispanic or Latino) ', value: 'White (Not Hispanic or Latino)' },
        { label: 'Black or African American (Not Hispanic or Latino) ', value: 'Black or African American (Not Hispanic or Latino)' },
        { label: 'Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)', value: 'Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)' },
        { label: 'Asian (Not Hispanic or Latino)', value: 'Asian (Not Hispanic or Latino)' },
        { label: 'American Indian or Alaska Native (Not Hispanic or Latino)', value: 'American Indian or Alaska Native (Not Hispanic or Latino)' },
        { label: 'Two or More Races (Not Hispanic or Latino)', value: 'Two or More Races (Not Hispanic or Latino)' },
    ];
    const handleEthnicityChange = (event) => {
        setEthnicityValue(event.target.value);
    };

    const handlePatientDOBDateChange = (d) => {
        setPatientDOBDate(d);
    }

    const handlePatientScanDateChange = (d) => {
        setPatientScanDate(d);
    }

    const [studyValue, setStudyValue] = React.useState('Report');
    const studyOptions = [
        { label: 'Report', value: 'Report' },
        { label: 'SimPlant Study', value: 'SimPlant Study' },
    ];
    const handleStudyChange = (event) => {
        setStudyValue(event.target.value);
    };

    const [studyPlusValue, setStudyPlusValue] = React.useState('Report 2-3 Days');
    const studyPlusOptions = [
        { label: 'No Add-ons', value: 'No Add-ons' },
        { label: '+$25 Endo/Implant Evaluation 3 or more teeth', value: '+$25 Endo/Implant Evaluation 3 or more teeth' },
        { label: '+$25 Comparison Report', value: '+$25 Comparison Report' },
        { label: '+$25 Rush Case (less than 24 hours)', value: '+$25 Rush Case (less than 24 hours)' },
    ];
    const handleStudyPlusChange = (event) => {
        setStudyPlusValue(event.target.value);
    };

    const [paymentMethod, setPaymentMethodValue] = React.useState('MC/Visa');
    const paymentMethodOptions = [
        { label: 'Credit Card (to be collected at a later time if not already on file)', value: 'Credit Card' },
        { label: 'Payment Link (will be sent via email along with the report)', value: 'Payment Link' },
        { label: 'Invoice (will be sent via email along with the report)', value: 'Invoice' }
    ];
    const handlePaymentMethod = (event) => {
        setPaymentMethodValue(event.target.value);
    };

    const handleRegionsChange = (event) => {
        setRegionsOfInterest(event.target.value);
    };

    const createMultiPartUpload = async (fileId, zippedFiles) => {
        const response = await axios.post(
            'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: {
                    fileID: fileId,
                    fileName: 'zippedUploads_'+fileId+'.zip',
                    fileType: zippedFiles.type,
                    fileOperation: "createUploadID",
                }
            }
        );
        setUploadID(response.data.body.UploadId);
    };

    const getPresignedUrlForEachChunk = async (uploadId, fileId, partNumber, chunk, zippedFiles) => {
        const response = await axios.post(
            'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: {
                    fileID: fileId,
                    fileName: 'zippedUploads_'+fileId+'.zip',
                    fileType: 'application/zip', //zippedFiles.type
                    uploadId: uploadId,
                    partNumber: partNumber,
                    fileOperation: "getSignedUrl",
                }
            }
        );
        return response.data.body
    };

    const uploadPart = async (fileChunk, presignedUrl, partNumber) => {
        let percentage = 0;
        await axios.put(presignedUrl, 
            fileChunk,
            {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    if (percentCompleted !== percentage) {
                        percentage = percentCompleted
                        setProgress(percentage);
                    }
                },
                headers: {
                    "Content-Type": file.type,
                },
            }
        ).then(response => {
            return { ETag: response.headers.get('ETag'), PartNumber: partNumber };
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const getUploadPart = async (fileId, uploadID, p, start, end, zippedFiles) => {
        const response = await axios.post(
            'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: {
                    fileID: fileId,
                    fileName: 'zippedUploads_'+fileId+'.zip',
                    fileType: 'application/zip',
                    uploadId: uploadID,
                    partNumber: p,
                    chunk: zippedFiles.slice(start, end),
                    fileOperation: "getUploadPart",
                }
        })
        return response.data.body
        // .then((response) => {
        //     return response.data.body
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
    };

    const completeMultipartUpload = async (fileId, uploadID, processedParts, zippedFiles) => {
        await axios.post(
            'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: {
                    fileID: fileId,
                    fileName: 'zippedUploads_'+fileId+'.zip',
                    fileType: zippedFiles.type,
                    uploadId: uploadID,
                    completedParts: processedParts,
                    fileOperation: 'completeUpload',
                }
            }
        ).then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const uploadFile = async (fileId, zippedFiles) => {
        if (!zippedFiles) {
            alert("Please add files to complete request");
            return;
        }

        // const parts = [];
        // const parentPromises = [];
        // const chunkSize = 10 * 1024 * 1024; // 10MB

        // await createMultiPartUpload(fileId, zippedFiles);

        // for (let partNumber = 1; partNumber <= Math.ceil(zippedFiles.size/ chunkSize); partNumber++) {
        //     const start = (partNumber - 1) * chunkSize;
        //     const end = partNumber * chunkSize;
        //     const chunk = zippedFiles.slice(start, end);
        
        //     let preSignedUrls = await getPresignedUrlForEachChunk(uploadID, fileId, 1, chunk);

        //     // parentPromises.push(getUploadPart(fileId, uploadID, partNumber, start, end, chunk));
        //     // const preSignedUrls = await getUploadPart(fileId, uploadID, partNumber, start, end, chunk);

        //     let percentage = 0;
        //     await axios.put(preSignedUrls, 
        //         chunk,
        //         {
        //             onUploadProgress: (progressEvent) => {
        //                 const percentCompleted = Math.round(
        //                     (progressEvent.loaded * 100) / progressEvent.total
        //                 );
        //                 if (percentCompleted !== percentage) {
        //                     percentage = percentCompleted
        //                     setProgress(percentage);
        //                 }
        //             },
        //         }
        //     ).then(async response => {
        //         parts.push({ ETag: response.headers.get('ETag').replace(/['"]+/g, ''), PartNumber: partNumber });
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        // }


        // const uploadResults = await Promise.all(parts); //parts

        // await completeMultipartUpload(fileId, uploadID, await Promise.all(uploadResults), zippedFiles);


        try {
            setProgressLabel('In progress...');
            let percentage = 1;
            const response = await axios.post(
                'https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/psurls/',
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*"
                    },
                    body: {
                        fileID: fileId,
                        fileName: 'zippedUploads_'+fileId+'.zip',
                        fileType: zippedFiles.type,
                        fileOperation: "getSignedUrl",
                    }
                }
            );

            await axios.put(response.data.body, 
                zippedFiles, 
                {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        if (percentCompleted !== percentage) {
                            percentage = percentCompleted + 1
                            setProgress(percentage);
                        }
                    },
                    headers: {
                        "Content-Type": zippedFiles.type,
                    },
                }
            ).catch((error) => {
                console.log(error)
            })
            .then((res) => {
                setProgressLabel('Submitted');
                if (res.statusText !== 'OK') {
                    alert("Referral file NOT submitted, Please try again.");
                }
            });
        } catch (error) {
            console.error("Error uploading file:", error);
        }

    };

    const handleZip = async () => {
        let percentage = 0;
        setProgressLabel('Zipping Files...');
        if (fileType !== 'application/zip') {
            const zip = new JSZip();
            for (const f of droppedFiles) {
                const arrBuff = await f.arrayBuffer();
                const filebuffer = Buffer.from(arrBuff, "base64");
                zip.file(f.name, filebuffer, {base64: true});
                // zip.file(f.name, f); 
            }
            return await zip.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: {level: 4 }}, 
                function updateCallback(metadata) {
                const percentCompleted = metadata.percent|0;
                if (percentCompleted !== percentage && percentCompleted !== 100) {
                    percentage = percentCompleted + 1
                    setProgress(percentage);
                }
            });
        } else {
            return droppedFiles[0];
        }
    };

    const onDrop = useCallback(files => {
        // console.log(files);
        setDroppedFiles(files);
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop, 
        // noClick: true
    });

    const handleFileSubmit = async (event) => {
        event.preventDefault();

        setProgress(1);
        setProgressLabel(' Processing...');

        const fileId = generateRandomString(12);

        await handleZip()
            .then((zippedFiles) => {
                setProgressLabel(' Submitting...');
                uploadFile(fileId, zippedFiles);
            }).catch((response) => {
                alert('Error with zipping');
            });

        const formData = {
            id: fileId || null,
            practice_name: event.target['practice_name'].value,
            practitioner_name: event.target['practitioner_name'].value.replace(/Dr\. |dr\. |Dr |dr /g, ''),
            contact_email: event.target['contact_email'].value, 
            office_number: event.target['office_number'].value, 
            patient_name: event.target['patient_name'].value, 
            patient_dob: patient_dob_date, 
            patient_gender: event.target['patient_gender'].value, 
            patient_ethnicity: event.target['patient_ethnicity'].value, 
            patient_scan_date: patient_scan_date, 
            report: event.target['report'].value, 
            report_plus: event.target['report_plus'].value, 
            payment_method: event.target['payment_method'].value, 
            // regions_of_interest: event.target['regions_of_interest'].value,
            regions_of_interest: regions_of_interest,
            file_name: 'zippedUploads_'+fileId+'.zip',
            file_type: 'application/zip',
            dttm: new Date(),
            user_ip: ip
        }

        axios.post(
            "https://zx6cnm6tid.execute-api.us-east-1.amazonaws.com/DEV/submission", 
            { 
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                },
                body: JSON.stringify(formData),
            } 
        )
        .then((response) => {
            if (response.data.statusCode !== 200) {
                alert("Referral NOT submitted, please try again");
            }
        })
        .catch((error) => {
            alert("Error uploading the file:", error);
        });
    };

    return (
        <div id="body">
            <div className="page">
                <div className="infomation_modal">
                    <label className="information_label">Disclaimer! This page is in development.</label>
                </div>
                <div className="navbar">
                    <div className="navbarImage" style={{width: dimensions.innerWidth/3}}>
                        <img alt="OMF" style={{height: '105px', width: '250px'}} src={logo}></img>
                    </div>
                    <div className="navBarActions" style={{width: dimensions.innerWidth/3}}>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/")}>Home</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/about-us")}>About Us</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/patient-referral")}>Patient Referral</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/services")}>Services</button>
                        <button style={{fontFamily: "Poppins, arial, sans-serif"}} className="navbarButton" onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {progress === 0  ?
                        <div className="">
                            <label htmlFor="formFile" id="patientReferralTitle" className="form-label">Patient Referral Information</label>
                            <div id="page_selection">
                                <form encType="multipart/form-data" onSubmit={handleFileSubmit}>
                                    <div style={{width: '700px',}}>
                                        <label htmlFor="formFile" id="formSubLabel">Practice Name</label>
                                        <input id="chat-input"
                                            name="practice_name"
                                            placeholder="Practice Name"
                                            maxLength="200"
                                            required
                                            />
                                        <label htmlFor="formFile" id="formSubLabel">Practitioner Name</label>
                                        <input id="chat-input"
                                            name="practitioner_name"
                                            placeholder="Practitioner Name"
                                            maxLength="100"
                                            required
                                            />
                                        <div style={{display: "", justifyContent: "space-evenly", alignItems: "center", height: 'auto', width: 'auto'}}>
                                            <label htmlFor="formFile" id="formSubLabel">Contact Email (Report and Invoice sent here)</label>
                                            <input id="chat-input"
                                                name="contact_email" 
                                                type='email'
                                                placeholder="Contact Email"
                                                maxLength="100"
                                                required
                                                />
                                            <label htmlFor="formFile" id="formSubLabel">Office Number</label>
                                            <input id="chat-input"
                                                name="office_number"
                                                placeholder="Office Number"
                                                maxLength="100"
                                                required
                                                />
                                        </div>
                                        <label htmlFor="formFile" id="formSubLabel">Patient Name</label>
                                        <input id="chat-input"
                                            name="patient_name"
                                            placeholder="Patient Name"
                                            maxLength="100"
                                            required
                                            />
                                        <div style={{display: "", justifyContent: "space-evenly", alignItems: "center", height: 'auto', width: 'auto'}}>
                                            <label htmlFor="formFile" id="formSubLabel">Patient Date of Birth</label>
                                            <DatePicker id=""
                                                oneTap
                                                format="MM_dd_yyyy"
                                                name="patient_dob"
                                                onChange={(date) => {
                                                    const dateStr = new Date(date);
                                                    setPatientDOBDate(dateStr.toISOString().slice(0, 10)); 
                                                }}
                                                onSelect={handlePatientDOBDateChange}
                                                placeholder="Patient DOB"
                                                block 
                                                required/>
                                            <label htmlFor="formFile" id="formSubLabel">Patient Gender</label>
                                            <select id="dropdown-input" 
                                                name="patient_gender" 
                                                value={genderValue}
                                                onChange={handleGenderChange}>
                                                {genderOptions.map((option) => (
                                                    <option key={option.label} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="formFile" id="formSubLabel">Patient Ethnicity</label>
                                            <select id="dropdown-input" 
                                                name="patient_ethnicity" 
                                                value={ethnicityValue}
                                                onChange={handleEthnicityChange}>
                                                {ethnicityOptions.map((option) => (
                                                    <option key={option.label} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{display: "", justifyContent: "space-evenly", alignItems: "center", height: 'auto', width: 'auto'}}>
                                            <label htmlFor="formFile" id="formSubLabel">Patient Scan Date</label>
                                            <DatePicker id=""
                                                oneTap
                                                format="MM_dd_yyyy"
                                                name="patient_scan_date"
                                                onChange={(date) => {
                                                    const dateStr = new Date(date);
                                                    setPatientScanDate(dateStr.toISOString().slice(0, 10)); 
                                                }}
                                                onSelect={handlePatientScanDateChange}
                                                placeholder="Patient Scan Date (Time)*"
                                                block 
                                                required/>
                                            <label htmlFor="formFile" id="formSubLabel">Report</label>
                                            <select id="dropdown-input" 
                                                name="report" 
                                                value={studyValue}
                                                onChange={handleStudyChange}>
                                                {studyOptions.map((option) => (
                                                    <option key={option.label} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="formFile" id="formSubLabel">Report Plus</label>
                                            <select id="dropdown-input"
                                                name="report_plus" 
                                                value={studyPlusValue}
                                                onChange={handleStudyPlusChange}>
                                                {studyPlusOptions.map((option) => (
                                                    <option key={option.label} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="formFile" id="formSubLabel">Preferred Payment Method</label>
                                            <select id="dropdown-input" 
                                                name="payment_method" 
                                                value={paymentMethod} 
                                                onChange={handlePaymentMethod}>
                                                {paymentMethodOptions.map((option) => (
                                                    <option key={option.label} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <label htmlFor="formFile" id="formSubLabel">Regions of Interest</label>
                                        <textarea id="regions-input" name="regions_of_interest" placeholder="Regions of Interest / Clinical Signs / Symptoms" value={regions_of_interest} onChange={handleRegionsChange} />
                                        <div style={{display: "flex", alignItems: "center", marginRight: '10px', marginTop: '15px', marginBottom: '10px'}}>
                                            <label htmlFor="formFile" id="formSubLabel">Upload non-proprietary DCM patient data</label>
                                        </div>
                                        <div className="fileDrop" {...getRootProps()}>
                                            {
                                            <div style={{display: 'block', justifyContent: 'space-between', alignItems: 'center',}}>
                                                <label style={{height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2px'}}>
                                                    Drag and Drop files <BsFiles size={25} color="#0284FE" style={{margin: '5px'}}/>  or folders <BsFolder size={25} color="#0284FE" style={{margin: '5px'}}/> here to upload
                                                </label>
                                                <div style={{display: "flex", justifyContent: 'space-evenly', alignItems: "center",}} >
                                                    <div htmlFor={"file_picker"} className=''>
                                                        <input id="file_picker" className="form-control" style={{display: "none"}} name="folder" multiple type="file" onChange={handleFolderInput}/>
                                                        <label className="btn" id="pickerFile" htmlFor={"file_picker"}>
                                                            Select file
                                                        </label>
                                                    </div>
                                                    <div htmlFor={"folder_picker"} className=''>
                                                        <input id="folder_picker" className="form-control" style={{display: "none"}} name="file" multiple directory="" webkitdirectory="" type="file" onChange={handleFolderInput}/>
                                                        <label className="btn" id="pickerFile" htmlFor={"folder_picker"}>
                                                            Select folder
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        {droppedFiles.length > 0 && 
                                        <div className='fileDisplay'>
                                            {droppedFiles.map((f, index) => (
                                                <div id="fileDisplayContainer" key={index}>
                                                    <span id="fileDisplayItems">
                                                        <BsFileEarmarkCheck size={25} color="#0284FE"/> 
                                                        {f.name}
                                                    </span>
                                                    <span oid="fileDisplayItems" onClick={() => fileRemove(f)}>
                                                        <BsFileX size={25} color="#c3223d"/>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                    <div className="form-group">
                                        <div style={{display: "flex", alignItems: "center", marginRight: '10px', marginTop: '15px', marginBottom: '10px'}}>
                                            <label htmlFor="formFile" >*Reports will take 2-3 business days to complete</label>
                                        </div>
                                        <button className="btn" id="submitFile" type="submit" >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    :
                        <div className="mb-3" style={{height: '250px', width: '500px', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                            <div className="mb-3" style={{height: '250px', width: '500px', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
                                <label id="formLabel" className="form-label" >Patient Information {progressLabel}</label>
                                {progressLabel !== 'Submitted' ?
                                    <>
                                        <label id="progress-loader">{progress-1}%</label>
                                        <label id="formWarning">Do not refresh the page until the progress has reached 100%</label>
                                    </>
                                :
                                    <>
                                        <FaCheck size={50} color="#0284FE" style={{marginTop: "25px", marginBottom: "25px"}}/>
                                        <label id="formWarning">Expect your report in 2-3 Business Days</label>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className="footer">
                    <label className="footerButton">Copyright © Carolina OMF Imaging - All Rights Reserved.</label>
                    <button className="footerButton" onClick={() => navigate("/privacy-policy")}>Privacy Policy</button>
                    <button className="footerButton" onClick={() => navigate("/site-map")}>Sitemap</button>
                </div>
            </div>
        </div>
    )
}

export default PatientReferralForm;